import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import {
  faGlobeAmericas,
  faUserShield,
  faTachometerAlt,
  faPencilRuler,
  faLayerGroup,
  faHandPointer,
} from '@fortawesome/free-solid-svg-icons';

import CardFeature from './CardFeature';

export const Features = () => {
  const controls = useAnimation();

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [inView, controls]);

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const cardsFeatures = [
    {
      id: 11,
      icon: faGlobeAmericas,
      title: 'Experiencia',
      desc: 'Contamos con más de 20 años de experiencia en diseño y desarrollo web, somos la solución ideal para desarrollar su proyecto',
    },
    {
      id: 12,
      icon: faUserShield,
      title: 'Seguridad',
      desc: 'Ofrecemos estrictas medidas de seguridad; que protegen y respaldan información sensible a la que solo usted tiene acceso.',
    },
    {
      id: 13,
      icon: faTachometerAlt,
      title: 'Rapidez',
      desc: 'Nuestros servidores garantizan una velocidad de respuesta optima, a las distintas peticiones e interacciones con el usuario.',
    },
    {
      id: 14,
      icon: faPencilRuler,
      title: 'Diseño',
      desc: 'Adecuamos la disposición de elementos dentro de cada diseño para lograr que el usuario se sienta comodo y en confianza con el sitio.',
    },
    {
      id: 15,
      icon: faLayerGroup,
      title: 'Escalabilidad',
      desc: 'Dejamos todo listo para que en el futuro usted pueda integrar nuevos módulos a su sitio, evitando volver a desarrollarlo.',
    },
    {
      id: 16,
      icon: faHandPointer,
      title: 'Interactividad',
      desc: ' Trabajamos con tecnologías avanzadas que permiten aprovechar al máximo el rendimiento del navegador y la experiencia interactiva con el usuario.',
    },
  ];

  return (
    <section className="home-features">
      <div className="container">
        {/* <h1 className="text-center">
          Implementación Avanzada en Diseño y Desarrollo Web
        </h1> */}
        <motion.div
          ref={ref}
          variants={list}
          animate={controls}
          initial="hidden"
          className="grid-3 text-center"
        >
          {cardsFeatures.map((card) => (
            <CardFeature
              key={card.id}
              icon={card.icon}
              title={card.title}
              desc={card.desc}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};
