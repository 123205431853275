import { PlanCard } from '../includes/PlanCard';

import plan1 from '../../../assets/svg/plans/web/basic.svg';
import plan2 from '../../../assets/svg/plans/web/influencer.svg';
import plan3 from '../../../assets/svg/plans/web/e-commerce.svg';
import plan4 from '../../../assets/svg/plans/web/portal.svg';

export const WebConcept = ({ history }) => {
  const plansInfo = [
    {
      id: 31,
      bookmark: 'p1',
      title: 'Básico',
      subTitle: 'Página Informativa',
      img: plan1,
      desc: [
        <p key={101}>
          Ideal para mostrar información relevante de su compañía, su historia y
          logros dentro de la industria. Se compone de una
          <span> presentación virtual</span> encargada de resaltar
          características atractivas del producto y/o servicio y una sección
          donde el usuario pueda contactar con ustedes.
        </p>,
        <p key={102}>
          Este tipo de páginas son muy comunes en el ámbito web, son utilizadas
          para dar a conocer rasgos relevantes de todos los beneficios y
          cualidades que se ofrecen.
        </p>,
        <p key={103}>
          Para obtener mejores resultados recomendamos realizar un Branding
          adecuado para mejorar la experiencia de usuario y así impactar de
          manera positiva la relación con la marca.
        </p>,
      ],
    },
    {
      id: 32,
      bookmark: 'p2',
      title: 'Influencer',
      subTitle: 'Publicaciones',
      img: plan2,
      desc: [
        <p key={201}>
          Diseñado para ofrecer todas las cualidades de una pagina dedicada a la
          generación de contenido. Las personas que tienen una trayectoria como
          Influencers en YouTube, TicTok o Discord entre otras; aportan
          contenido que interesa a los usuarios inmersos en estas plataformas.
        </p>,
        <p key={202}>
          Este plan canaliza todas estas aportaciones de contenido a un mismo
          lugar, al desarrollarlo con nosotros el trafico web fluye a través del
          un mismo sitio, que con el tiempo adquiere un mayor valor en Internet,
          destacado el estilo único del cliente y añadiendo secciones que
          complementen la importancia de cada publicación. Así aseguramos que
          nuestro cliente tambien sea reconocido en su propio dominio y no solo
          en la red social donde él se presenta.
        </p>,
      ],
    },
    {
      id: 33,
      bookmark: 'p3',
      title: 'E-Commerce',
      subTitle: 'Tiendas OnLine',
      img: plan3,
      desc: [
        <p key={301}>
          Orientado a la venta de productos y servicios dentro de una sola
          aplicación web. Nos hacemos cargo de optimizar el sitio e-comerse con
          un diseño bien estructurado, que pueda posicionarse de manera orgánica
          en los buscadores y que pertenezca a nuestro cliente sin tener que
          pagar comisiones de venta a intermediarios o terceros, pudiendo así
          enfocarse mas en resaltar beneficios relacionados al producto.
        </p>,
        <p key={302}>
          Se crea un área de administración a la que solo el propietario y quien
          usted decida, tienen acceso para modificar los items de artículos en
          cuanto a sus características; como pueden ser: titulo, imágenes,
          precio y descripción. De esta manera podemos aprovechar al máximo las
          bondades y facilidades que nos ofrece la venta on-line.
        </p>,
      ],
    },
    {
      id: 34,
      bookmark: 'p4',
      title: 'Portal',
      subTitle: 'Gestión de Contenido',
      img: plan4,
      desc: [
        <p key={401}>
          Se trata de gestionar flujos de alto contenido. Nuestra labor consiste
          en: categorizar, analizar, organizar y trabajar con la
          interfaz/experiencia de usuario (UX/IX), Para que toda esta
          información sea más digerible y de fácil comprensión.
        </p>,
        <p key={402}>
          Va orientado a compañías que ofrezcan diversos servicios con terceros
          en un portal a la creación de una red social donde la mayoría de
          usuarios comparte muchas de sus experiencias.
        </p>,
        <p key={403}>
          Todo esto se traduce en implementar y automatizar procesos a gran
          escala con la intención de persuadir al usuario a generar más
          contenido asegurando que el flujo de visitas incremente y puedan
          captarse nichos mas específicos.
        </p>,
      ],
    },
  ];

  return (
    <section className="card-concepts">
      <div className="container">
        <div className="grid-2 py-6">
          {plansInfo.map((plan) => (
            <PlanCard
              history={history}
              key={plan.id}
              bookmark={plan.bookmark}
              title={plan.title}
              subTitle={plan.subTitle}
              desc={plan.desc}
              img={plan.img}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
