import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import { Intro } from '../components/Intro';
import { DashRoutes } from './DashRoutes';

export const AppRouter = () => {
  return (
    <Router>
      <div className="main">
        <Switch>
          <Route exact path="/intro" component={Intro} />
          <Route path="/" component={DashRoutes} />
          <Redirect to="/intro" />
        </Switch>
      </div>
    </Router>
  );
};
