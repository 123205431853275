import { DesignPricing } from './ui/design/DesignPricing';
import { DesignHero } from './ui/design/DesignHero';
import { DesignJumbo } from './ui/design/DesignJumbo';
import { DesignConcepts } from './ui/design/DesignConcepts';

export const Design = ({ history }) => {
  return (
    <div className="design">
      <DesignHero />
      <DesignJumbo />
      <DesignPricing history={history} />
      <DesignConcepts history={history} />
    </div>
  );
};
