export const PlanCard = ({ title, subTitle, desc, history, img, bookmark }) => {
  const handleContact = () => {
    history.replace('/contact');
  };

  return (
    <>
      <div id={bookmark} className="text-concept">
        <h3 className="m-0">{title}</h3>
        <h4 className="m-0">{subTitle}</h4>
        {desc.map((des) => des)}
        <button onClick={handleContact} className="btn btn-red">
          Pedir
        </button>
      </div>
      <div className="bg-plans">
        <img src={img} alt={title} />
      </div>
    </>
  );
};
