export const DesignJumbo = () => {
  return (
    <div className="container">
      <div className="bg-light jumbotron">
        <h2 className="m-0">Diseño &#x26; Marketing</h2>
        <p className="mb-0">
          Entendemos con exactitud, la importancia que tiene:
          <span> El gestionar su marca de manera adecuada.</span> Nuestro
          objetivo consiste en desarrollar y comunicar su identidad con base en
          la innovación visual, logrando diferenciarla de su competencia.
        </p>
        <p className="mb-0">
          En <span>WebXMex</span> enlazamos conceptos que expresen asertivamente
          la filosofía de su compañía, incursionando en las más novedosas
          técnicas y tendencias en diseño, generando la representación gráfica
          de su marca, adaptandola cualquier tipo de aplicación y mediante el
          correcto uso del Branding, consolidamos el acercamiento con sus
          clientes estableciendo un firme vinculo de confianza.
        </p>
      </div>
    </div>
  );
};
