import { motion } from 'framer-motion';

import { concept, letters } from '../../../helpers/objects';

import bulb from '../../../assets/svg/design/bulb.svg';
import designLetters from '../../../assets/svg/design/design-letters.svg';

export const DesignHero = () => {
  return (
    <div className="second-hero">
      <div className="container">
        <div className="grid-same-2">
          <motion.div
            variants={concept}
            initial="hidden"
            animate="visible"
            className="element"
          >
            <img src={bulb} alt="bulb light" />
          </motion.div>
          <motion.div
            variants={letters}
            initial="hidden"
            animate="visible"
            className="letters"
          >
            <img src={designLetters} alt="design letters" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
