import { motion, useViewportScroll, useTransform } from 'framer-motion';
import singWhite from '../../../assets/svg/sign-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpaceShuttle } from '@fortawesome/free-solid-svg-icons';

import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

export const Function = () => {
  const isMobile = useMediaQuery({ maxWidth: 720 });

  const { ref, inView } = useInView({ threshold: 0 });

  const { scrollYProgress } = useViewportScroll();

  const value1 = useTransform(scrollYProgress, [0, 0, 1], [1, -1200, 450]);
  const value2 = useTransform(scrollYProgress, [0, 0, 1], [1, -700, 450]);

  const y1 = inView && { x: isMobile ? value1 : value2 };

  return (
    <section className="home-function">
      <div className="container">
        <div className="bg-light jumbotron">
          <div className="grid-2">
            <div ref={ref} className="text-function">
              <motion.div style={y1}>
                <FontAwesomeIcon icon={faSpaceShuttle} size="8x" />
              </motion.div>
              <h2>La Funcionalidad de una Aplicación Web</h2>
              <p className="mb-0">
                Ocupamos <span>Aplicaciones Web</span> para ofrecer diversos
                productos y/o servicios, pero para que estas sean realmente
                efectivas necesitamos construir adecuadamente los siguientes
                aspectos:
              </p>
              <div className="grid-2-same-all">
                <ul className="pl-1">
                  <li>Optimización del código</li>
                  <li>Experiencia e Interfaz de Usuario.</li>
                  <li>Implementación del Branding.</li>
                </ul>
                <ul className="pl-1">
                  <li>Posicionamiento en Buscadores.</li>
                  <li>Contenido Interesante y Original</li>
                  <li>Destacar el Valor Agregado</li>
                </ul>
              </div>
              <p className="mt-0">
                Se trata de ambientar e interactuar de manera asertiva con
                nuestros clientes, atendiendo sus gustos y necesidades para
                generar confianza y convencerlo de adquirir el beneficio que
                ofrecemos.
              </p>
            </div>
            <div className="bg-function">
              <motion.img
                whileHover={{
                  opacity: [0.1, 0.3, 0.3, 0.3, 0],
                  rotate: [0, 0, -100, 160, -500],
                  scale: [1, 1.3, 1.3, 1.3, 6],
                }}
                transition={{
                  duration: 2,
                  ease: 'easeInOut',
                  times: [0, 0.5, 0.7, 0.9, 1],
                }}
                src={singWhite}
                alt="function"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
