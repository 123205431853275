// components ui

import { Features } from './ui/home/Features';
import { Function } from './ui/home/Function';
import { Hero } from './ui/home/Hero';
import { ParallaxComponent } from './ui/home/ParallaxComponent';

// import { WavePath } from './ui/WavePath';

export const Home = ({ history }) => {
  return (
    <div className="home">
      {/* <WavePath /> */}
      <ParallaxComponent />
      <Hero />
      <Features />
      <Function />
    </div>
  );
};
