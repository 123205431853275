import { WebConcept } from './ui/webapp/WebConcept';
import { WebHero } from './ui/webapp/WebHero';
import { WebJumbo } from './ui/webapp/WebJumbo';
import { WebPricing } from './ui/webapp/WebPricing';

export const WebApp = ({ history }) => {
  return (
    <div className="webapp">
      <WebHero />
      <WebJumbo />
      <WebPricing history={history} />
      <WebConcept history={history} />
    </div>
  );
};
