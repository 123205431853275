import { motion } from 'framer-motion';

const svgFace = {
  initial: { y: 1, opacity: 0 },
  animate: {
    y: 0,
    scale: [0.1, 1.2, 0.7, 1.1, 0.9, 1.05, 1],
    opacity: 1,
    transition: {
      duration: 1.2,
      delay: 0.6,
      type: 'spring',
      dumping: 11,
      mass: 1.5,
      stiffness: 120,
    },
  },
};

const svgCrown = {
  initial: { opacity: 0 },
  animate: {
    scale: [0.1, 1.2, 0.7, 1.1, 0.9, 1.05, 1],
    opacity: 1,
    transition: {
      duration: 1.2,
      delay: 0.3,
      type: 'spring',
      dumping: 11,
      mass: 1.5,
    },
  },
};

const svgEyes = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 1, delay: 1.3 },
  },
};

const svgTransition = {
  duration: 0.7,
  delay: 1,
  type: 'spring',
  damping: 10,
  mass: 2,
};
const svgTransButton = {
  duration: 0.7,
  delay: 1.5,
  type: 'spring',
  damping: 10,
  mass: 2,
};

const svgHead = {
  initial: { opacity: 0, y: -200 },
  animate: { opacity: 1, y: 0, transition: svgTransition },
};

const svgMouth = {
  initial: { opacity: 0, y: 200 },
  animate: { opacity: 1, y: 0, transition: svgTransition },
};

const svgButton = {
  initial: { opacity: 0, y: 200 },
  animate: { opacity: 1, y: 0, transition: svgTransButton },
};

export const Intro = ({ history }) => {
  const handleIntro = () => {
    history.replace('/home');
  };

  return (
    <div className="bg-intro">
      <div className="intro">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1000 1000"
          version="1.1"
          fillRule="evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"
        >
          <g
            id="face"
            transform="matrix(1.05514,0,0,1.05514,-3031.15,-145.008)"
          >
            <motion.path
              variants={svgFace}
              initial="initial"
              animate="animate"
              d="M3272.77,786.874C3254.06,805.587 3223.72,805.587 3205.01,786.874C3144.65,726.511 3019.47,601.332 2962.2,544.067C2957.22,539.081 2955.73,531.583 2958.43,525.068C2961.12,518.554 2967.48,514.306 2974.53,514.306C2998.33,514.306 3023.36,514.306 3023.36,514.306L3060.25,551.2C3058.77,539.121 3058,526.802 3058,514.306C3058,450.585 3077.91,391.477 3111.85,342.857L3158.1,342.857C3163.61,342.857 3168.92,344.984 3172.9,348.792L3181.85,357.742C3144.81,399.38 3122.29,454.234 3122.29,514.306C3122.29,593.09 3161.02,662.898 3220.46,705.704C3234.76,715.953 3254.38,714.346 3266.82,701.906C3298.91,669.824 3358,610.735 3358,610.735C3358,610.735 3417.09,669.824 3449.18,701.906C3461.62,714.346 3481.24,715.953 3495.54,705.704C3554.98,662.898 3593.71,593.09 3593.71,514.306C3593.71,454.234 3571.19,399.38 3534.15,357.742L3543.1,348.792C3547.08,344.984 3552.39,342.857 3557.9,342.857L3604.15,342.857C3638.09,391.477 3658,450.585 3658,514.306C3658,526.802 3657.23,539.121 3655.75,551.2L3692.64,514.306C3692.64,514.306 3717.68,514.306 3741.47,514.306C3748.52,514.306 3754.88,518.554 3757.58,525.068C3760.27,531.583 3758.78,539.081 3753.8,544.067C3696.53,601.332 3571.35,726.511 3510.99,786.874C3492.28,805.587 3461.94,805.587 3443.23,786.874C3408.02,751.665 3358,701.649 3358,701.649L3272.77,786.874Z"
              fill="rgb(195,224,255)"
            />
          </g>
          <g id="crown" transform="matrix(1.05514,0,0,1.05514,-3031.15,-143)">
            <motion.path
              variants={svgCrown}
              initial="initial"
              animate="animate"
              d="M3513.14,257.538L3513.45,257.726C3541.56,274.806 3566.65,296.374 3587.71,321.429L3557.9,321.429C3546.77,321.429 3536.08,325.757 3528.09,333.498L3358,503.592L3187.91,333.498C3179.92,325.757 3169.23,321.429 3158.1,321.429L3128.29,321.429C3149.35,296.374 3174.44,274.806 3202.55,257.726L3202.86,257.538L3358,412.678L3513.14,257.538Z"
              fill="rgb(195,224,255)"
            />
          </g>
          <motion.g
            variants={svgEyes}
            initial="initial"
            animate="animate"
            id="eyes"
            transform="matrix(1,0,0,1,-2.27374e-13,-112)"
          >
            <g transform="matrix(1.05514,0,0,1.05514,-3031.15,-33.0082)">
              <path
                d="M3411.03,557.143C3411.03,557.143 3444.67,590.782 3466.33,612.435C3471.11,617.219 3477.79,619.596 3484.52,618.906C3491.25,618.217 3497.32,614.534 3501.03,608.88C3518.95,581.736 3529.43,549.225 3529.43,514.286C3529.43,491.891 3525.12,470.493 3517.3,450.876L3411.03,557.143Z"
                fill="rgb(195,224,255)"
              />
            </g>
            <g transform="matrix(1.05514,0,0,1.05514,-3031.15,-33.0082)">
              <path
                d="M3304.97,557.143C3304.97,557.143 3271.33,590.782 3249.68,612.435C3244.89,617.219 3238.21,619.596 3231.48,618.906C3224.75,618.217 3218.68,614.534 3214.97,608.88C3197.05,581.736 3186.57,549.225 3186.57,514.286C3186.57,491.891 3190.88,470.493 3198.7,450.876L3304.97,557.143Z"
                fill="rgb(195,224,255)"
              />
            </g>
          </motion.g>
          <g
            id="mouth"
            transform="matrix(1.05514,0,0,1.05514,-3031.15,-145.008)"
          >
            <motion.path
              variants={svgMouth}
              initial="initial"
              animate="animate"
              d="M3358,732.13L3416.25,790.384C3400.86,793.607 3381.12,796.415 3358,796.415L3358,796.415C3334.88,796.415 3315.14,793.607 3299.75,790.384L3358,732.13Z"
              fill="rgb(255,61,123)"
            />
          </g>
          <g
            id="head"
            transform="matrix(1.05514,0,0,1.05514,-3031.15,-145.008)"
          >
            <motion.path
              variants={svgHead}
              initial="initial"
              animate="animate"
              d="M3480.19,259.957C3443.85,242.515 3402.8,232.149 3358,232.149C3313.63,232.149 3272.29,242.447 3235.81,259.957L3358,382.149L3480.19,259.957Z"
              fill="rgb(255,61,123)"
            />
          </g>
        </svg>
        <motion.div
          variants={svgButton}
          initial="initial"
          animate="animate"
          className="welcome"
        >
          <button onClick={handleIntro} className="btn btn-red">
            Bienvenido
          </button>
        </motion.div>
      </div>
    </div>
  );
};
