import React from 'react';

export const WebJumbo = () => {
  return (
    <div className="container">
      <div className="bg-light jumbotron">
        <h2 className="m-0">Diseño y Desarrollo Web</h2>
        <p className="mb-0">
          En <span>WebXMex</span>, integramos diferentes tecnologías de
          <span> FrontEnd</span> y <span>BackEnd</span> basados en
          <span> JavaScript</span> como <span>React</span>, <span>NextJs</span>{' '}
          y <span>NodeJs</span>, estas permiten mejorar significativamente la
          presencia de una aplicación web, destacándola en las busquedas del
          navegador. Logrando integrarla en los resultados de manera eficaz
          mediante palabras clave previamente establecidas.
        </p>
        <p className="mb-0">
          Nuestro equipo de colaboradores está altamente calificado y capacitado
          para ofrecerle la mejor alternativa de solución a las expectativas que
          su proyecto requiera.
          <span>
            {' '}
            !Nos entusiasma mucho poder conocer acerca de sus proyectos!
          </span>{' '}
          Estamos en la mejor disposición de ayudarle.
        </p>
      </div>
    </div>
  );
};
