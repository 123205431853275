import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { PricingCard } from '../includes/PricingCard';

export const DesignPricing = ({ history }) => {
  // Animated cards
  const controls = useAnimation();

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [inView, controls]);

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  // Info pricing design
  const PricingInfo = [
    {
      id: 81,
      bookId: 'p1',
      title: 'Editorial',
      price: 900,
      subTitle: 'Diseño Editorial',
      list: [
        'Diseño de Flyer',
        'Trípticos o Dípticos',
        'Diseño de Poster',
        'Diseño Corporativo',
        'Catalogos y Reportes',
        'Diseño de Empaque',
      ],
    },
    {
      id: 82,
      bookId: 'p2',
      title: 'Logotipo',
      price: 6500,
      subTitle: 'Diseño de Logotipo',
      list: [
        '3 Propuestas de Logo',
        'Evaluación Gráfica',
        '3 Ajustes y Revisiones',
        'Abstracción y Síntesis',
        'AI, EPS, PDF, PNG, JPG',
        'Evidencia del Proceso',
      ],
    },
    {
      id: 83,
      bookId: 'p3',
      title: 'Identidad',
      price: 11500,
      subTitle: 'Identidad Corporativa',
      list: [
        '3 Propuestas de Logo',
        'Aplicaciones',
        '5 ajustes y revisiones',
        'Manual de Identidad (PDF)',
        'Papelería Corporativa',
        'Certificado de Derechos',
      ],
    },
    {
      id: 84,
      bookId: 'p4',
      title: 'Branding',
      price: 22000,
      subTitle: 'Gestión de Marca',
      list: [
        'SEO SEM Google',
        'Social Media',
        'Diseño de Recursos',
        'MockUps',
        'Renders 3D',
        'Presentación Digital',
      ],
    },
  ];
  return (
    <div className="pricing-card">
      <div className="container text-center">
        <motion.div
          ref={ref}
          variants={list}
          animate={controls}
          initial="hidden"
          className="grid-4"
        >
          {PricingInfo.map((price) => (
            <PricingCard
              key={price.id}
              bookId={price.bookId}
              title={price.title}
              subTitle={price.subTitle}
              price={price.price}
              list={price.list}
              history={history}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};
