import { FormComponent } from './ui/contact/FormComponent';

export const Contact = () => {
  return (
    <div className="contact">
      <div className="container-md">
        <FormComponent />
      </div>
    </div>
  );
};
