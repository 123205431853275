const elementTransition = {
  type: 'spring',
  damping: 13,
  mass: 2,
  delay: 0.3,
};
export const concept = {
  visible: { opacity: 1, x: 0, transition: elementTransition },
  hidden: { opacity: 0, x: 500 },
};
export const letters = {
  visible: { opacity: 1, x: 0, transition: elementTransition },
  hidden: { opacity: 0, x: -500 },
};

const logoTransition = {
  type: 'spring',
  damping: 10,
  mass: 2.5,
  delay: 0.3,
};

export const logo = {
  visible: { opacity: 1, y: 0, transition: logoTransition },
  hidden: { opacity: 0, y: -200 },
};

export const slogan = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
