import { motion } from 'framer-motion';
import { logo, slogan } from '../../../helpers/objects';

import logoWeb from '../../../assets/svg/logo.svg';

export const Hero = () => {
  return (
    <div className="home-hero">
      <div className="container">
        <motion.img
          variants={logo}
          initial="hidden"
          animate="visible"
          height="90"
          src={logoWeb}
          alt="logo"
        />
        <motion.h1
          variants={slogan}
          initial="hidden"
          animate="visible"
          transition={{ delay: 1.6, duration: 0.4 }}
          className="text-center"
        >
          <span>&#x2774;</span> development <span>&#x26;</span> environment{' '}
          <span>&#x2775;</span>
        </motion.h1>
      </div>
    </div>
  );
};
