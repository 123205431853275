import { Redirect, Switch, Route } from 'react-router-dom';
import { Contact } from '../components/Contact';

import { Design } from '../components/Design';
import { Home } from '../components/Home';
import { WebApp } from '../components/WebApp';

import { NavBar } from '../components/ui/includes/NavBar';
import { Footer } from '../components/ui/includes/Footer';
import ScrollToTop from './ScrollToTop';

export const DashRoutes = () => {
  return (
    <>
      <NavBar />
      <>
        <ScrollToTop />
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/design" component={Design} />
          <Route exact path="/webapp" component={WebApp} />
          <Route exact path="/contact" component={Contact} />
          <Redirect to="/intro" />
        </Switch>
      </>
      <Footer />
    </>
  );
};
