import { motion } from 'framer-motion';

import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';

export const PricingCard = ({
  title,
  subTitle,
  price,
  list,
  bookId,
  history,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const isTablet = useMediaQuery({ maxWidth: 1140 });
  const isDesktop = useMediaQuery({ maxWidth: 1320 });

  let offset;

  const HandleOffset = (offset) => {
    isMobile
      ? (offset = -90)
      : isTablet
      ? (offset = -180)
      : isDesktop
      ? (offset = -190)
      : (offset = -290);
    return offset;
  };

  // Animation
  const item = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2, type: 'spring', damping: 10 },
    },
    hidden: { opacity: 0, y: -300 },
  };

  const handleContact = () => {
    history.replace('/contact');
  };

  return (
    <motion.div variants={item} className="card-plan">
      <h2>{title}</h2>
      <p>Desde</p>
      <h2 className="m-0">
        <span>
          $
          {Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
            price
          )}
        </span>
      </h2>
      <p>MXN</p>
      <h3 className="mb-0">{subTitle}</h3>
      <ul className="mt-0">
        {list.map((li, idx) => (
          <li key={idx}>{li}</li>
        ))}
      </ul>
      <div className="flex-2-all">
        <Link
          to={bookId}
          smooth="easeInCubic"
          offset={HandleOffset(offset)}
          duration={500}
          className="btn btn-dark"
        >
          Info
        </Link>
        <button onClick={handleContact} className="btn btn-red">
          Pedir
        </button>
      </div>
    </motion.div>
  );
};
