import React from 'react';

export const WebOptions = () => {
  return (
    <>
      <option value="">--Seleccione un plan web--</option>
      <option value="basic">Plan Básico</option>
      <option value="blogger">Plan Blogger</option>
      <option value="e-commerce">Plan E-Commerce</option>
      <option value="portal">Plan Portal</option>
    </>
  );
};
