import { motion } from 'framer-motion';

import { concept, letters } from '../../../helpers/objects';

import computer from '../../../assets/svg/webapp/computer.svg';
import webLetters from '../../../assets/svg/webapp/web-letters.svg';

export const WebHero = () => {
  return (
    <div className="second-hero">
      <div className="container">
        <div className="grid-same-2">
          <motion.div
            variants={concept}
            initial="hidden"
            animate="visible"
            className="element"
          >
            <img src={computer} alt="bulb light" />
          </motion.div>
          <motion.div
            variants={letters}
            initial="hidden"
            animate="visible"
            className="letters mb-3"
          >
            <img src={webLetters} alt="design letters" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
