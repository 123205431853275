import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardFeature({ icon, title, desc }) {
  const item = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.2, type: 'spring', damping: 10 },
    },
    hidden: { opacity: 0, x: -300 },
  };
  return (
    <motion.div variants={item} className="card">
      <div className="card-icon">
        <FontAwesomeIcon icon={icon} size="7x" />
      </div>
      <div className="card-head">
        <h2>{title}</h2>
      </div>
      <div className="card-body">{desc}</div>
    </motion.div>
  );
}
