import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';

import { WebOptions } from './WebOptions';
import { DesignOptions } from './DesignOptions';

export const FormComponent = () => {
  const [formSending, setFormSending] = useState(false);

  const transitionForm = {
    duration: 0.5,
    type: 'spring',
    stiffness: 120,
    delay: 0.3,
  };

  const msgError = {
    hidden: { x: -200, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: transitionForm,
    },
  };

  const formAnimation = {
    hidden: { y: -300, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: transitionForm },
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        pricing: '',
        picked: '',
      }}
      validate={(values) => {
        let errors = {};
        // validación nombre
        if (!values.name) {
          errors.name = 'Por favor ingrese un nombre';
        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.name)) {
          errors.name = 'El nombre solo puede contener letras y espacios';
        }
        // validación email
        if (!values.email) {
          errors.email = 'Por favor ingrese un email';
        } else if (
          !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email)
        ) {
          errors.email = 'El email no es valido';
        }
        // validación Teléfono
        if (!values.phone) {
          errors.phone = 'Por favor ingrese un teléfono';
        } else if (!/^[2-9]{2}[0-9]{8}$/.test(values.phone)) {
          errors.phone = 'El teléfono no es valido';
        }
        // validación Teléfono
        if (!values.pricing) {
          errors.pricing = 'Seleccione una opción';
        }
        return errors;
      }}
      onSubmit={(values, { resetForm }) => {
        // console.log(values);
        emailjs
          .send(
            'service_csq6b1k',
            'template_k7e31am',
            values,
            'user_IaSBkeVCIYgYEEeOq5qe4'
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        resetForm();
        setFormSending(true);
        setTimeout(() => setFormSending(false), 5000);
      }}
    >
      {({ errors, values }) => (
        <motion.div
          variants={formAnimation}
          initial="hidden"
          animate="visible"
          className="wrapper"
        >
          <Form className="contact-form">
            <h3 className="mt-0">Datos de contacto</h3>
            <Field name="name" type="text" placeholder="Nombre y Apellido" />
            <ErrorMessage
              name="name"
              component={() => (
                <motion.div
                  variants={msgError}
                  initial="hidden"
                  animate="visible"
                  className="error-alert"
                >
                  {errors.name}
                </motion.div>
              )}
            />

            <Field name="email" type="text" placeholder="Correo Electrónico" />
            <ErrorMessage
              name="email"
              component={() => (
                <motion.div
                  variants={msgError}
                  initial="hidden"
                  animate="visible"
                  className="error-alert"
                >
                  {errors.email}
                </motion.div>
              )}
            />

            <Field
              name="phone"
              type="text"
              placeholder="Teléfono a 10 Dígitos"
            />
            <ErrorMessage
              name="phone"
              component={() => (
                <motion.div
                  variants={msgError}
                  initial="hidden"
                  animate="visible"
                  className="error-alert"
                >
                  {errors.phone}
                </motion.div>
              )}
            />

            <div role="group" className="color-dark">
              <label>
                <Field type="radio" name="picked" value="web" />
                Web
              </label>
              <label className="pl-1">
                <Field type="radio" name="picked" value="design" />
                Diseño
              </label>
            </div>

            <Field as="select" name="pricing">
              {values.picked === 'web' ? (
                <WebOptions />
              ) : values.picked === 'design' ? (
                <DesignOptions />
              ) : (
                <option value="">--Seleccione Web o Diseño--</option>
              )}
            </Field>
            <ErrorMessage
              name="pricing"
              component={() => (
                <motion.div
                  variants={msgError}
                  initial="hidden"
                  animate="visible"
                  className="error-alert"
                >
                  {errors.pricing}
                </motion.div>
              )}
            />

            <div className="grid-2-all">
              <button type="submit" className="btn btn-red">
                Enviar
              </button>

              {formSending && (
                <motion.p
                  variants={msgError}
                  initial="hidden"
                  animate="visible"
                  className="m-0 success-alert"
                >
                  Formulario enviado exitosamente
                </motion.p>
              )}
            </div>
          </Form>
        </motion.div>
      )}
    </Formik>
  );
};
