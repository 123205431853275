import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

export const ParallaxComponent = () => {
  const { ref, inView } = useInView({ threshold: 0 });

  const { scrollYProgress } = useViewportScroll();

  const y1 = useTransform(scrollYProgress, [0, 0.4, 0.8], [0, -250, 100]);
  const y2 = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -300, -100]);
  const y3 = useTransform(scrollYProgress, [0, 0.25, 0.8], [0, -350, -200]);
  const y4 = useTransform(scrollYProgress, [0, 0.7, 0.6], [0, -360, 100]);

  const style1 = inView && { y: y1 };
  const style2 = inView && { y: y2 };
  const style3 = inView && { y: y3 };
  const style4 = inView && { y: y4 };

  return (
    <div ref={ref} className="absolute">
      <div className="gid-4-all">
        <motion.div style={style1} className="circle one">
          <FontAwesomeIcon icon={faArrowCircleUp} size="6x" />
        </motion.div>
        <motion.div style={style2} className="circle two ">
          <FontAwesomeIcon icon={faArrowCircleUp} size="5x" />
        </motion.div>
        <motion.div style={style3} className="circle three">
          <FontAwesomeIcon icon={faArrowCircleUp} size="4x" />
        </motion.div>
        <motion.div style={style4} className="circle four">
          <FontAwesomeIcon icon={faArrowCircleUp} size="7x" />
        </motion.div>
      </div>
    </div>
  );
};
