import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { motion } from 'framer-motion';
import sign from '../../../assets/svg/sign.svg';

export const NavBar = () => {
  const [toggle, setToggle] = useState({
    isOpen: false,
    active: '',
    open: '',
  });

  const { isOpen, active, open } = toggle;
  const isMobile = useMediaQuery({ maxWidth: 539 });

  const handleMenu = () => {
    if (!isOpen && isMobile) {
      setToggle({
        isOpen: true,
        active: 'active',
        open: 'open',
      });
    } else {
      setToggle({
        isOpen: false,
        active: '',
        open: '',
      });
    }
  };

  const toggleUp = () => {
    isMobile &&
      setToggle({
        isOpen: false,
        active: '',
        open: '',
      });
  };

  return (
    <motion.header
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -80, opacity: 0 }}
      transition={{
        delay: 2,
        duration: 0.5,
        ease: 'circOut',
      }}
      className="bg-header"
    >
      <div className="sign">
        <Link to="/home">
          <motion.img
            whileHover={{
              scale: 1.1,
              rotate: -5,
              transition: { duration: 0.5 },
            }}
            src={sign}
            alt="Signo"
          />
        </Link>
      </div>
      <div className="menu-btn">
        <svg
          className={`ham ham6 menu-btn__burger ${open} ${active}`}
          viewBox="0 0 100 100"
          width="60"
          onClick={handleMenu}
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
          />
          <path
            className="line middle"
            d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
          />
          <path
            className="line bottom"
            d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
          />
        </svg>
      </div>
      <nav className={`nav ${open}`}>
        <ul className={`menu-nav ${open}`}>
          <li className={`menu-nav__item ${open}`}>
            <Link
              to="/webapp"
              className="link menu-nav__link"
              onClick={toggleUp}
            >
              WebApp
            </Link>
          </li>
          <li className={`menu-nav__item ${open}`}>
            <Link
              to="/design"
              className="link menu-nav__link"
              onClick={toggleUp}
            >
              Diseño
            </Link>
          </li>
          <li className={`menu-nav__item ${open}`}>
            <Link
              to="/contact"
              className="link menu-nav__link"
              onClick={toggleUp}
            >
              Contacto
            </Link>
          </li>
        </ul>
      </nav>
    </motion.header>
  );
};
