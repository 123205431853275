import React from 'react';

export const DesignOptions = () => {
  return (
    <>
      <option value="">--Seleccione un plan de diseño--</option>
      <option value="editorial">Diseño Editorial</option>
      <option value="logo">Diseño de Logo</option>
      <option value="identity">Diseño de Identidad</option>
      <option value="branding">Branding</option>
    </>
  );
};
