import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { PricingCard } from '../includes/PricingCard';

export const WebPricing = ({ history }) => {
  // Animated cards
  const controls = useAnimation();

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [inView, controls]);

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  // Info pricing web
  const PricingInfo = [
    {
      id: 41,
      bookId: 'p1',
      title: 'Básico',
      price: 7900,
      subTitle: 'Página Informativa',
      list: [
        'Hasta 7 Links',
        'HTML, CSS y JavaScript',
        'Formulario de Contacto',
        'Imágenes sin Copyright',
        'Certificado SSL Incluido',
        'Hosting gratis por un año',
      ],
    },
    {
      id: 42,
      bookId: 'p2',
      title: 'Influencer',
      price: 19700,
      subTitle: 'Publicaciones',
      list: [
        'Hasta 25 links',
        'Desarrollado con React',
        'Base de datos',
        'Imágenes Propias',
        'Panel de captura',
        'Interactividad avanzada',
      ],
    },
    {
      id: 43,
      bookId: 'p3',
      title: 'E-Commerce',
      price: 29700,
      subTitle: 'Tienda Online',
      list: [
        'Hasta 50 links',
        'Desarrollado con React',
        'Base de datos',
        'Panel de Administración',
        'Generación de Imágenes',
        'Animaciones y Eventos',
      ],
    },
    {
      id: 44,
      bookId: 'p4',
      title: 'Portal',
      price: 49800,
      subTitle: 'Gestion de Contenido',
      list: [
        'Links ilimitados',
        'Desarrollado con React',
        'Alta seguridad',
        'Test de funcionalidad',
        'Panel de Administración',
        'Conceptos UX/UI',
      ],
    },
  ];
  return (
    <div className="pricing-card">
      <div className="container text-center">
        <motion.div
          ref={ref}
          variants={list}
          animate={controls}
          initial="hidden"
          className="grid-4"
        >
          {PricingInfo.map((price) => (
            <PricingCard
              key={price.id}
              bookId={price.bookId}
              title={price.title}
              subTitle={price.subTitle}
              price={price.price}
              list={price.list}
              history={history}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};
