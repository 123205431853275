import { PlanCard } from '../includes/PlanCard';

import plan1 from '../../../assets/svg/plans/design/editorial.svg';
import plan2 from '../../../assets/svg/plans/design/logo-design.svg';
import plan3 from '../../../assets/svg/plans/design/identity.svg';
import plan4 from '../../../assets/svg/plans/design/branding.svg';

export const DesignConcepts = ({ history }) => {
  const plansInfo = [
    {
      id: 21,
      bookmark: 'p1',
      title: 'Editorial',
      subTitle: 'Diseño Editorial',
      img: plan1,
      desc: [
        <p key={111}>
          Ofrecemos el servicio de <span>Diseño Editorial</span>, para cubrir
          todos los requerimientos que su compañía tenga al querer publicar y
          comunicar cualidades propias, haciéndolas visualmente más atractivas,
          a traves de medios impresos.
        </p>,
        <p key={112}>
          La composición final en cada uno de nuestros trabajos tiene la
          finalidad de armonizar y equilibrar los elementos gráficos, otorgando
          óptima calidad estetica para hacer funcionales los recursos que usted
          necesite.
        </p>,
        <p key={113}>
          Nos enfocamos en brindarle el asesoramiento adecuado a cada una de sus
          inquietudes, enfatizando nuestro compromiso y experiencia que nos hace
          diferentes en el medio.
        </p>,
      ],
    },
    {
      id: 22,
      bookmark: 'p2',
      title: 'Logotipo',
      subTitle: 'Diseño de Logotipo',
      img: plan2,
      desc: [
        <p key={211}>
          La comunicación directa y abstracta de toda la identidad en cualquier
          empresa, se logra mediante la correcta integración que relaciona: el
          concepto y el diseño de un Logotipo.
        </p>,
        <p key={212}>
          En este sentido desarrollamos un proceso que involucra el estilo que
          nuestros clientes, a traves de su filosofía, desean transmitir,
          considerando elementos como: disposición, tipografia, colores, forma y
          composicion.
        </p>,
        <p key={213}>
          Al final de este proceso proponemos tres representaciones simbolicas
          que visualmente enfaticen la conjunción del todos los elementos
          graficos. Así garantizamos la obtención de un
          <span> Logotipo Profesional</span> que defina fielmente la identidad
          de su compañía.
        </p>,
      ],
    },
    {
      id: 23,
      bookmark: 'p3',
      title: 'Identidad',
      subTitle: 'Manual de Identidad Corporativa',
      img: plan3,
      desc: [
        <p key={311}>
          Este manual engloba la descripción: del concepto, simbolismo y
          representación del Logotipo, justificando cada elemento utilizado
          dentro de su composicion con base en los principios establecidos por
          la compañía, incluye además, el uso adecuado y preciso de sus
          distintas aplicaciones, enfatizando estrictamente que esta permitido y
          que nó, en el uso cotidiano del mismo.
        </p>,
        <p key={312}>
          Esta guia dentro del manual previene malas practicas al momento de
          implementar su usabilidad, como pueden ser: rotulos, impresos,
          presentaciones digitales, papeleria corporativa, entre otras, con la
          intencion de estructurar y definir las reglas para su aplicación.
        </p>,
      ],
    },
    {
      id: 24,
      bookmark: 'p4',
      title: 'Branding',
      subTitle: 'Gestión de Marca',
      img: plan4,
      desc: [
        <p key={411}>
          La marca se define como aquella conjunción de elementos que integran
          la identidad visual de una compañía.
        </p>,
        <p key={412}>
          Realizamos todo el proceso para la construcción de su marca haciendola
          más conocida, más deseada y más positiva en su concepto, con la firme
          intencion de generar valor y posicionamiento en la mente sus clientes
          y/o consumidores, diseñando acciones y estrategias que nos permitan
          influir en la decision de compra.
        </p>,
        <p key={413}>
          La gestión de marca se realiza mediante canales de comunicacion que se
          encarguen de transmitir coherencia y fortaleza que la audiencia
          reconoce e integra a su percepción.
        </p>,
      ],
    },
  ];

  return (
    <section className="card-concepts">
      <div className="container">
        <div className="grid-2 py-6">
          {plansInfo.map((plan) => (
            <PlanCard
              history={history}
              key={plan.id}
              bookmark={plan.bookmark}
              title={plan.title}
              subTitle={plan.subTitle}
              desc={plan.desc}
              img={plan.img}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
