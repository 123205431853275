import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faLinkedin,
  faGithubSquare,
} from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="grid-sm text-red">
          <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
          <FontAwesomeIcon icon={faGithubSquare} size="2x" />
        </div>
        <p className="text-center mb-0">WebXMex © Derechos Reservados</p>
      </div>
    </footer>
  );
};
